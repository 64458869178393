import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AiTT',
    component: () => import('../views/Home'),
  },

  {
    path: '/chart-test/:method?',
    name: 'Chart test',
    component: () => import('../views/ChartTest'),
  },

  {
    path: '/diapasons',
    name: 'Diapasons',
    component: () => import('../views/Diapasons'),
  },

  {
    path: '/chart-runs',
    name: 'Runs',
    component: () => import('../views/ChartRuns'),
  },

  /**
   * Terminal routes
   */
  {
    path: '/terminal',
    component: () => import('../terminal/Layout.vue'),
    children: [
      {
        path: 'profile',
        name: 'Terminal — Profile',
        component: () => import('../terminal/views/Auth/Auth.vue'),
      },

      {
        path: 'browse',
        name: 'Terminal — Browse',
        component: () => import('../terminal/views/BrowseTab.vue'),
      },

      {
        path: 'positions',
        name: 'Terminal — Positions',
        component: () => import('../terminal/views/PositionsTab.vue'),
        children: [
          {
            path: ':positionId',
            name: 'Terminal — Position',
            component: () => import('../terminal/views/PositionsTab.vue'),
          },
        ],
      },

      {
        path: 'trade',
        name: 'Terminal — Trade',
        component: () => import('../terminal/views/TradeTab.vue'),
        meta: {
          direction: 1,
        },
      },

      {
        path: 'buy',
        name: 'Terminal — Buy',
        component: () => import('../terminal/views/TradeTab.vue'),
        props: (route) => {
          return {
            direction: 1,
          }
        },
      },

      {
        path: 'sell',
        name: 'Terminal — Sell',
        component: () => import('../terminal/views/TradeTab.vue'),
        props: (route) => {
          return {
            direction: -1,
          }
        },
      },

      {
        path: 'tools',
        alias: '',
        name: 'Terminal — Tools',
        component: () => import('../terminal/views/ToolsTab.vue'),
      },

    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.name
  next()
})

export default router
