import axios from 'axios'

/**
 * Get run list
 * @returns {Promise<[Candle]>}
 */
export default async function getExporterList() {
  const res = await axios.get('/exporter/list')

  return res.data
}
