import axios from 'axios'

/**
 * Get candles
 * @param {object} params — params
 * @param {number} params.brokerId – broker id
 * @param {string} params.ticker – ticker
 * @param {number} params.timeframe – timeframe
 * @param {number} params.startDate – start date
 * @param {number} params.endDate – end date
 * @returns {Promise<[Candle]>}
 */
export default async function getTestFigures(params, method) {
  const res = await axios.get(`/get-research-diapasons`, {
    params,
  })

  return res.data
}
