import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { createPinia, PiniaVuePlugin } from 'pinia'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ResizeObserver from 'v-resize-observer'

import App from './App.vue'
import router from './router'
import api from './api'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const pinia = createPinia()

Vue.use(ResizeObserver)

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(PiniaVuePlugin)

Vue.use(Toast, {
  transition: 'Vue-Toastification__slideBlurred',
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
})

Vue.prototype.$api = api

Vue.prototype.$dev = process.env.VUE_APP_DEBUG

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')
