import axios from 'axios'

import getCandles from './get-candles'
import getBrokers from './get-brokers'
import getTestFigures from './get-test-figures'
import getResearchDiapasons from './get-research-diapasons'
import getZigzag from './get-zigzag'

import getExporterList from './get-exporter-list'
import getExporterRead from './get-exporter-read'
import exporterRun from './exporter-run'

axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN

export default {
  getCandles,
  getBrokers,
  getTestFigures,
  getResearchDiapasons,
  getZigzag,

  /**
   * Exporter methods
   */
  getExporterList,
  getExporterRead,
  exporterRun,
}
