import axios from 'axios'

/**
 * Get brokers
 * @returns {Promise<[Candle]>}
 */
export default async function exporterRun(params) {
  const res = await axios.get('/exporter/run', { params })

  return res.data
}
