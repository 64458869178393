import axios from 'axios'

/**
 * Read specific run
 * @param {Object} params
 * @param {String} params.hash
 * @returns {Promise<[Candle]>}
 */
export default async function getExporterRead(params) {
  const res = await axios.get('/exporter/read', {
    params,
  })

  return res.data
}