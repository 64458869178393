import axios from 'axios'

/**
 * Get brokers
 * @returns {Promise<>}
 */
export default async function getBrokers() {
  const res = await axios.get('/get-brokers')

  return res.data
}
